import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const privacy = () => (
  <Layout>
    <SEO title="Privacy" />
    <div class="px-4 mt-2 mb-5">
      <h1 class="display-5 mb-4 fw-bold text-center">Privacy Policy</h1>
      <div class="col-lg-6 mb-4 mx-auto lead">
        <h3>Our Privacy Policy</h3>
        <p>
          Inspire Global Mobility Consulting LTD does not collect any personal
          data from the www.igmobility.co.uk Website.
        </p>
        <p>
          This privacy policy supplements any other notices and privacy policies
          and is not intended to override them.
        </p>
        <h3>Contact Details</h3>
        <p>
          If you have any questions about this privacy policy or our privacy
          practices, please contact:
        </p>
        <p>Paul Barnes, Director Inspire Global Mobility Consulting</p>
        <p>paul@igmobility.com</p>
        <p>+44 (0) 7534 565 888</p>
        <h3>Changes to the privacy policy</h3>
        <p>
          We keep our privacy policy under regular review. This version was last
          updated on 27 April 2021.
        </p>
        <h3>Third party links</h3>
        <p>
          This website may include links to third-party websites, plug-ins and
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their
          privacy statements. When you leave our website, we encourage you to
          read the privacy policy of every website you visit.
        </p>
        <h3>Right to complain</h3>
        <p>
          You also have the right to make a complaint at any time to a
          supervisory authority. Please follow the link (for the authority local
          to you) to find out more about supervisory authorities, their role and
          contact details. 
          <u>
            <a
              href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/what-are-data-protection-authorities-dpas_en"
              class="link-text"
              target="_blank"
            >
              What are data protection authorities (DPAs)? — European
              Commission.
            </a>
          </u>
        </p>
        <p>
          The Information Commissioner’s Office, the UK regulator for data
          protection issues, can be 
          <u>
            <a href="https://ico.org.uk/" class="link-text" target="_blank">
              found here.
            </a>
          </u>
          We would, however, appreciate the chance to deal with your concerns
          before you approach the ICO, so we ask that you please contact us in
          the first instance.
        </p>
      </div>
    </div>
  </Layout>
)

export default privacy
